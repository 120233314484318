type DialogMode = 'confirm' | 'require'
type DialogState = 'prompt' | 'processing' | 'success'

export const useSwitchAccountProfileStore = defineStore('switch-account-profile', () => {
  const mode = ref<DialogMode>()
  const state = ref<DialogState>('prompt')
  const isModalOpen = ref(false)

  function showConfirmSwitch() {
    mode.value = 'confirm'
    isModalOpen.value = true
  }

  function showRequireSwitch() {
    mode.value = 'require'
    isModalOpen.value = true
  }

  async function closeModal() {
    isModalOpen.value = false
    // Prevents flashing an empty state
    await new Promise(resolve => setTimeout(resolve, 500))
    state.value = 'prompt'
    mode.value = undefined
  }

  return {
    state,
    mode,
    isModalOpen,
    closeModal,
    showConfirmSwitch,
    showRequireSwitch,
  }
})
